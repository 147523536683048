/* Shell Font */

@font-face {
  font-family: 'Shell Font';
  src: url(/fonts/Shell-Font/ShellLight.woff) format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Shell Font';
  src: url(/fonts/Shell-Font/ShellBook.woff) format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Shell Font';
  src: url(/fonts/Shell-Font/ShellMedium.woff) format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Shell Font';
  src: url(/fonts/Shell-Font/ShellBold.woff) format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Shell Font';
  src: url(/fonts/Shell-Font/ShellHeavy.woff) format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
