html,
body {
  scroll-behavior: smooth;
  height: 100%;
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Arial, Roboto, 'Helvetica Neue', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0em;
  font-style: normal;
  color: rgba(52, 52, 52, 1);
  background: #f7f7f7;
  -webkit-font-smoothing: antialiased;
}

@media (prefers-reduced-motion: reduce) {
  html,
  body {
    scroll-behavior: auto;
  }
}

#__next {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

button:not([disabled]) {
  cursor: pointer;
}
